import PropTypes from "prop-types";
import React, { useEffect } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";

const MathContainer = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  //   const cate = data.categories.map((value, i) => {
  //     return (
  //       <span className="d-inline" key={i}>
  //         {value}
  //         {i !== data.categories.length - 1 && " , "}
  //       </span>
  //     );
  //   });
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                <h2 className="title">
                  Exam
                  <br /> Details
                </h2>
                <div className="work-details-list mt-12">
                  <div className="details-list">
                    <img
                      src="images/categoryIcons/date.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Date</label>
                    <span style={{ textAlign: "center" }}>June/2025</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/grade.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Grades</label>
                    <span style={{ paddingLeft: "45px" }}>3-12</span>
                  </div>

                  <div className="details-list">
                    <img
                      src="images/categoryIcons/location.png"
                      alt=""
                      width="40px"
                    />
                    &nbsp;
                    <label>Location</label>
                    <span style={{ textAlign: "center" }}>UCLA, CA</span>
                  </div>

                  {/* <div className="details-list">
                    <label>Awards</label>
                    <span>asdasdasd</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h3 className="title" data-aos="fade-up">
                    MathixCel
                  </h3>

                  <div className="desc mt-8">
                    <ol>
                      <li>Primary Level (Grades 3-5):</li>
                      <ul>
                        <li>
                          Focus on basic arithmetic, problem-solving, and simple
                          geometric concepts.
                        </li>
                      </ul>
                      <li>Intermediate Level (Grades 6-8):</li>
                      <ul>
                        <li>
                          Includes algebra, geometry, and introductory calculus
                          concepts.
                        </li>
                      </ul>
                      <li>Advanced Level (Grades 9-12):</li>
                      <ul>
                        <li>
                          Covers advanced algebra, trigonometry, calculus, and
                          problem-solving techniques.
                        </li>
                      </ul>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="custom-column-thumbnail mt-lg-14 mt-1"
              data-aos="fade-up"
            >
              <h5>Preliminary Round:</h5>
              <ul>
                <li>Online tests are administered in each country.</li>
                <li>
                  Questions are designed to assess mathematical knowledge,
                  problem-solving skills, and critical thinking.
                </li>
                <li>
                  Topics may include arithmetic, algebra, geometry, and basic
                  calculus depending on the grade level.
                </li>
                <li>Number of questions: TBD, typically 25-30 questions</li>
                <li>
                  Format: Online all answer choice and/or classic questions
                </li>
                <li>Technical: Shuffle questions</li>
                <li>
                  Duration: To be determined by XCEL, typically 60-90 minutes.
                </li>
                <li>
                  Medals: Gold, Silver, and Bronze awarded based on percentile
                  ranking.
                </li>
              </ul>

              <h5>Final Round:</h5>
              <ul>
                <li>On-site competition held in the USA.</li>
                <li>Number questions: TBD, typically 25-30 questions</li>
                <li>
                  Format: Paper-based answer choice questions or/and Challenges
                  maybe?
                </li>
                <li>
                  Technical: Shuffle paper-based/or just standard all the same
                  questions.
                </li>
                <li>
                  Live mathematical challenges, problem-solving tasks, and group
                  activities.
                </li>
                <li>
                  Finalists compete in various rounds covering advanced
                  mathematical concepts.
                </li>
                <li>Judged by a panel of experts in mathematics education.</li>
                <li>
                  Medals, certificates, and cash prizes are awarded to top
                  performers.
                </li>
              </ul>
              <h5>Math Category by Grade Levels with Aligned Topics:</h5>

              <h6>Grade Levels:</h6>
              <ul>
                <li>Grade 3-4</li>
                <li>Grade 5-6</li>
                <li>Grade 7-8</li>
                <li>Grade 9-10</li>
                <li>Grade 11-12</li>
              </ul>
              <h6>Preliminary Round:</h6>
              <ul>
                <li>
                  Each grade level participates in a preliminary round designed
                  to assess their mathematical skills based on internationally
                  recognized standards.
                </li>
                <li>
                  Questions and problems are crafted to cover topics relevant to
                  the respective grade level&apos;s curriculum and international
                  standards.
                </li>
                <li>
                  Participants are evaluated on their understanding and
                  application of mathematical concepts within their grade range.
                </li>
              </ul>
              <h6>Global Finals:</h6>
              <ul>
                <li>
                  Top performers from each grade level advance to the global
                  finals held in the USA, where they face more challenging and
                  comprehensive mathematical problems.
                </li>
                <li>
                  The finals assess participants&apos; abilities to apply
                  mathematical principles to solve complex problems, aligned
                  with international standards.
                </li>
                <li>
                  Participants compete within their respective grade categories
                  for medals and recognition.
                </li>
              </ul>
              <h6>Aligned Topics by Grade Levels:</h6>
              <ul>
                <li>Grade 3-4:</li>
                <ul>
                  <li>Place value</li>
                  <li>Fractions and decimals</li>
                  <li>Basic operations (multiplication, division)</li>
                  <li>Geometry (perimeter, area)</li>
                  <li>Data and graphs</li>
                  <li>Basic algebraic concepts</li>
                </ul>
                <li>Grade 5-6:</li>
                <ul>
                  <li>Operations with fractions and decimals</li>
                  <li>Ratios and proportions</li>
                  <li>Geometry (angles, triangles, quadrilaterals)</li>
                  <li>Integers and number theory</li>
                  <li>Algebraic expressions and equations</li>
                  <li>Statistics and probability basics</li>
                </ul>
                <li>Grade 7-8:</li>
                <ul>
                  <li>Algebra (linear equations, inequalities)</li>
                  <li>Geometry (circles, transformations, volume)</li>
                  <li>Ratios, rates, and proportions</li>
                  <li>Data analysis and probability</li>
                  <li>Exponents and scientific notation</li>
                </ul>
                <li>Grade 9-10:</li>
                <ul>
                  <li>Algebra II (quadratic equations, functions)</li>
                  <li>Geometry (trigonometry, congruence, similarity)</li>
                  <li>Advanced statistics and probability</li>
                  <li>Exponential and logarithmic functions</li>
                  <li>Introduction to calculus concepts</li>
                </ul>
                <li>Grade 11-12:</li>
                <ul>
                  <li>Precalculus topics (polynomials, rational functions)</li>
                  <li>
                    Advanced calculus (differential and integral calculus)
                  </li>
                  <li>Advanced topics in geometry and trigonometry</li>
                  <li>Probability and statistics (advanced topics)</li>
                  <li>Matrices and vectors</li>
                </ul>
              </ul>
              <h5>Scoring and Evaluation:</h5>
              <ul>
                <li>
                  Scoring criteria are based on the accuracy, depth, and clarity
                  of participants&apos; solutions to math problems, in alignment
                  with international standards.
                </li>
                <li>
                  Performance is assessed relative to the complexity and
                  difficulty of mathematical concepts covered within each grade
                  level.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MathContainer.propTypes = {
  data: PropTypes.object,
};

export default MathContainer;
