import * as React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import { createSvgIcon } from "@mui/material/utils";

const PlusIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

export default function SubjectCardsForRegistration({
  alreadyAddedExams,
  data,
  handleExamAdd,
  studentData,
}) {
  const navigate = useNavigate();
  const disableIfExamAdded = alreadyAddedExams.includes(data.examName);
  const examIsInSession = JSON.parse(sessionStorage.getItem("exams"));
  const logoImage = (examName) => {
    if (examName === "Math") {
      return "images/bg/math.png";
    }
    if (examName === "CogAt") {
      return "images/bg/breadcrumb-bg-two1.png";
    }
    if (examName === "Robotics") {
      return "images/bg/robotix.png";
    }
  };
  const examPaidOrNot = studentData.filter(
    (exam) => exam.examName === data.examName
  );
  const handleExamStartPage = (examName) => {
    switch (examName) {
      case "Math":
        navigate("/dash/math");
        break;
      case "CogAt":
        navigate("/dash/cogat");
        break;
      case "Robotics":
        navigate("/dash/robotics");
        break;
      default:
        return;
    }
  };
  return (
    <>
      {data.examName && data.isActive && (
        <div
          className="col-sm-2 col-xl-3 col-12 mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: isMobile ? "70%" : "22%",
          }}
        >
          <Card
            sx={{
              fontSize: "2rem",
              maxWidth: 315,
              borderRadius: "5%",
              boxShadow: "2px 2px 15x 10px grey",
              color: "Black",
            }}
          >
            {data.examName === "Art" ? (
              <h2
                style={{
                  padding: "5px",
                  color: "#0d3234",
                  textAlign: "center",
                }}
              >
                Art
              </h2>
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/${logoImage(data.examName)}`}
                alt={data.examName}
                style={{
                  padding: "10px",
                  marginBottom: "3px",
                }}
              />
            )}
            <Divider
              sx={{
                borderBottomWidth: 3,
                bgcolor: "black",
                margin: "3px 5px",
              }}
            />

            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/images/patternSmall.png`}
              alt={data.examName}
            />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={examPaidOrNot.length && "Paid"}
                color="secondary"
              >
                {!examPaidOrNot[0]?.isPayed ? (
                  <Button
                    disabled={
                      !data.isActive ||
                      disableIfExamAdded ||
                      (examIsInSession &&
                        examIsInSession.find((i) => i.name === data.examName))
                    }
                    variant="contained"
                    endIcon={<PlusIcon />}
                    onClick={() => handleExamAdd(data)}
                  >
                    Add test
                  </Button>
                ) : (
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "teal",
                      marginRight: "4px",
                      padding: "5px 15px",
                    }}
                    onClick={() => handleExamStartPage(data.examName)}
                  >
                    Start Test
                  </Button>
                )}
              </Badge>
            </Box>
          </Card>
        </div>
      )}
    </>
  );
}
