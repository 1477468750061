import React, { useEffect } from "react";
import AOS from "aos";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import NavScrollTop from "../components/NavScrollTop.jsx";
import math1 from "./math1.jpg";
import math2 from "./math2.jpg";
import math3 from "./math3.jpg";
import math4 from "./math4.jpg";
import math5 from "./math5.jpg";
import math6 from "./math6.jpg";
import math7 from "./math7.jpg";

import cogat1 from "./cogat1.jpg";
import cogat2 from "./cogat2.jpg";
import cogat3 from "./cogat3.jpg";
import cogat4 from "./cogat4.jpg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FirstLocalRoundResults = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <React.Fragment>
      <NavScrollTop>
        <SEO title="Xcel || Results" />
        <Header />
        <Breadcrumb
          image="images/bg/xcelpattern3.png"
          title="Math and Cogat Results"
          content="Home"
          contentTwo="Results"
          backgroundProp="cover"
        />
        {/* <div className="section section-padding">
          <div className="container">
            <div className="row pt--100 pb--80">
              <h1 style={{ textAlign: "center" }}>MathXCEL Results</h1>
              <img style={{ margin: "0" }} src={math1} alt="" />
              <img style={{ margin: "0" }} src={math2} alt="" />
              <img style={{ margin: "0" }} src={math3} alt="" />
              <img style={{ margin: "0" }} src={math4} alt="" />
              <img style={{ margin: "0" }} src={math5} alt="" />
            </div>
            <div className="row pt--100 pb--80">
              <h1 style={{ textAlign: "center" }}>CogAtXCEL Results</h1>
              <img style={{ margin: "0" }} src={cogat1} alt="" />
              <img style={{ margin: "0" }} src={cogat2} alt="" />
              <img style={{ margin: "0" }} src={cogat3} alt="" />
              <img style={{ margin: "0" }} src={cogat4} alt="" />
            </div>
          </div>
        </div> */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontWeight: "bold",
            }}
          >
            <h1
              style={{
                width: "70%",
                margin: "auto",
                display: "block",
                textAlign: "center",
              }}
            >
              MathXCEL
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            <img style={{ margin: "0" }} src={math1} alt="" />
            <img style={{ margin: "0" }} src={math2} alt="" />
            <img style={{ margin: "0" }} src={math3} alt="" />
            <img style={{ margin: "0" }} src={math4} alt="" />
            <img style={{ margin: "0" }} src={math5} alt="" />
            <img style={{ margin: "0" }} src={math6} alt="" />
            <img style={{ margin: "0" }} src={math7} alt="" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontWeight: "bold",
            }}
          >
            <h1
              style={{
                width: "70%",
                margin: "auto",
                display: "block",
                textAlign: "center",
              }}
            >
              CogAtXCEL
            </h1>
          </AccordionSummary>
          <AccordionDetails>
            <img style={{ margin: "0" }} src={cogat1} alt="" />
            <img style={{ margin: "0" }} src={cogat2} alt="" />
            <img style={{ margin: "0" }} src={cogat3} alt="" />
            <img style={{ margin: "0" }} src={cogat4} alt="" />
          </AccordionDetails>
        </Accordion>
        <Footer />
        <ScrollToTop />
      </NavScrollTop>
    </React.Fragment>
  );
};

export default FirstLocalRoundResults;
